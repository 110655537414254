.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #0078ba;
  line-height: 1.27272727;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.25em;
  font-weight: normal;
  font-family: 'FrutigerNextBold', sans-serif;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #0068a1;
}
.button:active {
  background-color: #00476e;
}
.desk {
  max-width: 87.02439024vw;
}
.main {
  width: 100%;
}
.main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.main .cb-album .body,
.main .head,
.main .foot,
.main .part {
  margin-right: 1.62907268%;
  margin-left: 1.62907268%;
  width: 96.74185464%;
}
.main .tiny {
  width: 46.74185464%;
}
.main > .slim {
  width: 50%;
}
.main > .slim .head,
.main > .slim .foot,
.main > .slim .part {
  margin-right: 3.25814536%;
  margin-left: 3.25814536%;
  width: 93.48370927%;
}
.main > .slim.cb-album .body {
  margin-right: 3.25814536%;
  margin-left: 3.25814536%;
  width: 93.48370927%;
}
.main > .slim .tiny {
  width: 93.48370927%;
}
.side {
  width: 100%;
}
.side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.side .cb-album .body,
.side .head,
.side .foot,
.side .part {
  margin-right: 1.416122%;
  margin-left: 1.416122%;
  width: 97.16775599%;
}
.side .tiny {
  width: 30.50108932%;
}
.side > .slim {
  width: 33.33333333%;
}
.side > .slim .head,
.side > .slim .foot,
.side > .slim .part {
  margin-right: 4.24836601%;
  margin-left: 4.24836601%;
  width: 91.50326797%;
}
.side > .slim.cb-album .body {
  margin-right: 4.24836601%;
  margin-left: 4.24836601%;
  width: 91.50326797%;
}
.side > .slim .tiny {
  width: 91.50326797%;
}
.farnorth {
  width: 100%;
}
.farnorth > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.farnorth .cb-album .body,
.farnorth .head,
.farnorth .foot,
.farnorth .part {
  margin-right: 1.416122%;
  margin-left: 1.416122%;
  width: 97.16775599%;
}
.farnorth .tiny {
  width: 30.50108932%;
}
.farnorth > .slim {
  width: 33.33333333%;
}
.farnorth > .slim .head,
.farnorth > .slim .foot,
.farnorth > .slim .part {
  margin-right: 4.24836601%;
  margin-left: 4.24836601%;
  width: 91.50326797%;
}
.farnorth > .slim.cb-album .body {
  margin-right: 4.24836601%;
  margin-left: 4.24836601%;
  width: 91.50326797%;
}
.farnorth > .slim .tiny {
  width: 91.50326797%;
}
.main {
  width: 89.46188341%;
  margin-left: -1.4573991%;
}
.side {
  width: 102.91479821%;
  margin-left: -1.4573991%;
}
.farnorth {
  width: 102.91479821%;
  margin-left: -1.4573991%;
}
.side .seam.wide {
  width: 66.66666667%;
}
.side .seam.wide .part {
  margin-left: 2.12418301%;
  margin-right: 2.12418301%;
}
.side .seam.wide .part.tall {
  width: 95.75163399%;
}
.side .seam.wide .part.tiny {
  width: 45.75163399%;
}
.side .flat {
  margin-left: 1.416122%;
  margin-right: 1.416122%;
}
.side .flat.wide {
  width: 63.83442266%;
}
.side .flat.slim {
  width: 30.50108932%;
}
#view .side .flat .part.text {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 2;
  box-sizing: border-box;
  padding: 20px 26px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: all 0.4s;
}
#view .side .flat .part.text .text-section--norm {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transform: scale(0.5);
  transition: all 0.4s;
}
#view .side .flat:hover .part.text,
#view .side .flat:focus .part.text {
  height: 100%;
  background-color: rgba(255, 200, 0, 0.8);
}
#view .side .flat:hover .part.text .text-section--norm,
#view .side .flat:focus .part.text .text-section--norm {
  opacity: 1;
  transform: scale(1);
  max-height: initial;
}
/*# sourceMappingURL=./screen-large.css.map */